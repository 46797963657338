import React from 'react'


export default function Footer() {
  return (
    <div className="footer">
 
    <p>Powered by <a href="https://cosmicjs.com">CosmicJs</a> created by <a href="https://github.com/3ndrius">3ndrius</a> Inspired by <a href="https://medium.com/">Medium</a> </p>
    
     
    </div>
  )
}
